<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- nombre -->
            <v-col cols="9" class="py-0">
              <v-text-field
                v-model.trim="nombre"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(nombre, 50),
                    rules.requiredTrim(nombre),
                  ])
                "
              ></v-text-field>
            </v-col>
            <!-- codigo postal -->
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="codigoPostal"
                label="Código postal"
                type="text"
                dense
                :rules="rules.validCodigoPostal"
                outlined
                v-mask="'#####'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- localidad -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="localidadSelected"
                ref="localidad"
                :items="localidades"
                item-text="value"
                item-value="id"
                label="Localidad"
                outlined
                clearable
                dense
                :rules="localidadSelected !== 0 ? rules.required : []"
              >
              </v-autocomplete>
            </v-col>
            <!-- Email -->
            <v-col cols="12" sm="6" md="6" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                :rules="rules.email.concat([rules.maxLength(emailEntidad, 200)])"
                label="Email"
                v-model="emailEntidad"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- domicilio -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model.trim="domicilio"
                label="Domicilio"
                dense
                outlined
                :rules="
                  entFinId === 0
                    ? [rules.maxLength(domicilio, 50)]
                    : rules.required.concat([
                        rules.maxLength(domicilio, 50),
                        rules.requiredTrim(domicilio),
                      ])
                "
              ></v-text-field>
            </v-col>
            <!-- telefono -->
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="telefono"
                label="Teléfono"
                type="text"
                dense
                outlined
                v-mask="'####################'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- formas de pago -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="formaPagoSelected"
                ref="formaPago"
                :items="formasPago"
                item-text="value"
                item-value="id"
                label="Forma de pago"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- caja -->
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete
                v-model="cajaSelected"
                ref="caja"
                :items="cajas"
                item-text="cajaNombre"
                item-value="cajaId"
                label="Caja"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditEntidadFinanciera",
  directives: { mask },
  props: ["entFinId"],
  data: () => ({
    formEditTitle: "Editar entidad financiera",
    title: enums.titles.ENTIDADES_FINANCIERAS_APP,
    rules: rules,
    isFormValid: false,
    localidades: [],
    cajas: [],
    formasPago: [],
    entidadFinanciera: {},
    nombre: null,
    domicilio: null,
    localidadSelected: null,
    cajaSelected: null,
    formaPagoSelected: null,
    codigoPostal: null,
    telefono: null,
    emailEntidad: null
  }),
  created() {
    if (this.entFinId != null) {
      this.setEntidadFinanciera(this.entFinId);
    } else {
      this.newEntidadFinanciera();
    }
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getConfiguracionEntidadesFinancierasById:
        "configuracion/getConfiguracionEntidadesFinancierasById",
      postConfiguracionEntidadesFinancieras:
        "configuracion/postConfiguracionEntidadesFinancieras",
      getLocalidades: "afiliaciones/getLocalidades",
      getCajeros: "configuracion/getCajeros",
      getFormasDePago: "devengamientos/getFormasDePago",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const localidad = await this.getLocalidades();
      this.localidades = localidad;
      const caja = await this.getCajeros();
      this.cajas = caja.data;
      const formaPago = await this.getFormasDePago();
      this.formasPago = formaPago;
    },
    async setEntidadFinanciera() {
      const response = await this.getConfiguracionEntidadesFinancierasById(
        this.entFinId
      );
      // VER ESTO
      this.nombre = response.nombre;
      this.domicilio = response.domicilio;
      this.localidadSelected = response.localidadId;
      this.cajaSelected = response.caja;
      this.formaPagoSelected = response.formaPago;
      this.codigoPostal = response.codigoPostal;
      this.telefono = response.telefono;
      this.emailEntidad = response.entFinEmail;
    },
    async newEntidadFinanciera() {
      this.formEditTitle = "Nueva entidad financiera";
    },
    async saveEdit() {
      const data = {
        entFinId: this.entFinId !== null ? this.entFinId : null,
        nombre: this.nombre,
        domicilio: this.domicilio,
        localidadId: this.localidadSelected,
        formaPago:
          this.formaPagoSelected != null ? this.formaPagoSelected : null,
        caja: this.cajaSelected != null ? this.cajaSelected : null,
        codigoPostal: this.codigoPostal,
        telefono: this.telefono,
        entFinEmail: this.emailEntidad
      };
      const res = await this.postConfiguracionEntidadesFinancieras(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style></style>
